<template>
  <div class="app-student">
    <HeaderPages title=" تطبيق الطالب" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-12">
          <label>اضافة صورةالتطبيق</label>
          <input
            type="file"
            @change="handleCertificatesChange"
            accept="image/*"
            multiple
            ref="certificatesInput"
            style="display: none"
          />
          <div class="upload-icon" @click="triggerCertificatesInput">
            <i class="fa fa-file-image-o"></i>
            <span> اختيار صوره</span>
          </div>
          <div class="certificates-preview">
            <div
              v-for="(src, index) in form.certificateImagesSrc"
              :key="index"
              class="certificate-image"
            >
              <img :src="src" alt="Certificate Preview" />
              <i
                class="fa fa-times delete-icon"
                @click="removeCertificate(index)"
              ></i>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-12">
          <label for="name"> الرابط Android </label>
          <div class="input">
            <input type="text" id="name" placeholder="اكتب هنا " />
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-12">
          <label for="name"> الرابط IOS </label>
          <div class="input">
            <input type="text" id="name" placeholder="اكتب هنا " />
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-12">
          <label for="name"> الوصف</label>
          <br />
          <div class="input-textarea">
            <textarea id="w3review" name="w3review"></textarea>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      form: {
        certificate_images: [], // Initialize the array
        certificateImagesSrc: [], // Initialize the array for image previews
      },
      CurriculaOptions: [],
    };
  },

  methods: {
    // add img 2
    handleCertificatesChange(event) {
      const files = Array.from(event.target.files);
      this.form.certificate_images.push(...files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.certificateImagesSrc.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    triggerCertificatesInput() {
      this.$refs.certificatesInput.click();
    },
    removeCertificate(index) {
      this.form.certificate_images.splice(index, 1);
      this.form.certificateImagesSrc.splice(index, 1);
    },
    // add img 2
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
.input-textarea {
  width: 100%;
}
.input-textarea textarea {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  border: 1px solid var(--main);
}
</style>
