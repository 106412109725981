import { defineStore } from "pinia";
import axios from "axios";
import swal from "sweetalert2";
import router from "@/router";

export const useCompetitionAddStore = defineStore("competitionAdd", {
  state: () => ({
    token: localStorage.getItem("token") || null,
    data: {},
  }),

  actions: {
    async addCompetition(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
        const response = await axios.post("add_competition", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.data = response.data;
        if (response.data.status) {
          swal.fire({
            icon: "success",
            title: "Success",
            text: "Competition Added Successfully",
          });
          await router.replace("/index-competion");
        } else {
          swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
