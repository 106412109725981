import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useStudentsAddStore = defineStore("StudentsAdd", {
  state: () => ({
    students: [],
    countries: [],
    bloods: [],
    groups: [],
  }),
  actions: {
    async fetchCountries() {
      try {
        const response = await axios.get("organization_fetch_countries");
        if (response.data.status) {
          this.countries = response.data.data;
          console.log(this.countries, "Countries fetched");
        } else {
          console.log("Error fetching countries");
        }
      } catch (error) {
        console.error("Fetch countries error:", error);
      }
    },
    async fetchBloods() {
      try {
        const response = await axios.get("organization_fetch_blood_types");
        if (response.data.status) {
          this.bloods = response.data.data;
        } else {
          console.log("Error fetching blood types");
        }
      } catch (error) {
        console.error("Fetch blood types error:", error);
      }
    },
    async fetchGroups() {
      try {
        const response = await axios.get("organization_fetch_groups");
        if (response.data.status) {
          this.groups = response.data.data;
        } else {
          console.log("Error fetching groups");
        }
      } catch (error) {
        console.error("Fetch groups error:", error);
      }
    },

    async addStudents(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else if (key !== "imageSrc") {
            formData.append(key, data[key]);
          }
        });

        const response = await axios.post("add_user", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          Swal.fire("Success", "Student added successfully", "success");
          router.push("student-index");
          return response.data;
        } else {
          throw new Error(response.data.message || "Failed to add the teacher");
        }
      } catch (error) {
        console.error("Error adding teacher:", error);
      }
    },
  },
});
