<template>
  <div class="blog">
    <header-pages
      title="عرض المدونه"
      button="اضافة  مدونه "
      link="/add-blog"
    ></header-pages>
    <tables-page-vue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteGroup"
    />

  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { useBlogs } from "@/stores/blogs/BlogStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    TablesPageVue,
  },
  data() {
    return {
      tableHeaders: ["	Id", "الصورة", "الاسم", "	وصف", "التصنيف", "القسم"],
      editLink: "/edit-blog",
      viewLink: "/view-group",
    };
  },

  computed: {
    ...mapState(useBlogs, {
      blogs: (state) => state.blogs,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.employees, "ssss");
      return this.blogs.map((emp) => [
        emp.id,
        emp.image,
        emp.title,
        emp.description,
        emp.blog_hashtags.map((item) => item.title),
        emp.blog_categories.map((item) => item.title),
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const curriculaStore = useBlogs();
      curriculaStore.getBlogs(page);
    },
    async handleDeleteEmployee(id) {
      const employeesStore = useBlogs();
      console.log(id);

      await employeesStore.deleteBlog(id);
    },
  },

  async mounted() {
    const employeesStore = useBlogs();
    await employeesStore.getBlogs();
  },
};
</script>
