<template>
  <div class="employees">
    <HeadersPages title="الطلاب" button="+ اضافة طالب" link="/add-student" />
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteEmployee"
      :ismaster="ismaster"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useStudentsStore } from "@/stores/student/studentindex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: [
        "ID",
        "الصور",
        "اسم الطالب",
        "يالبريد الالكتروني",
        "رقم الهاتف",
      ],
      editLink: "/edit-student",
      viewLink: "/view-student",
    };
  },
  computed: {
    ...mapState(useStudentsStore, {
      students: (state) => state.students,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.students, "ssss");
      return this.students.map((emp) => [
        emp.id,
        emp.image,
        emp.name,
        emp.email,
        emp.phone,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const StudentsStore = useStudentsStore();
      StudentsStore.fetchStudents(page);
    },
    async handleDeleteEmployee(id) {
      const studentsStore = useStudentsStore();
      console.log(id);

      await studentsStore.deleteStudent(id);
    },
  },

  async mounted() {
    const studentsStore = useStudentsStore();
    await studentsStore.fetchStudents();
  },
};
</script>
