<template>
    <div class="serves">
      <header-pages
        title=" الخدمات"
        button=" اضافة  "
        link="/add-serves"
      ></header-pages>
      <tables-page-vue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="editLink"
        :viewLink="viewLink"
        @delete="handleDeleteGroup"
      />
    </div>
  </template>
  
  <script>
  import HeaderPages from "@/components/headerpages/HeaderPages.vue";
  import TablesPageVue from "@/components/tables/TablesPage.vue";
  export default {
    name: "GroupsIndex",
    components: {
      HeaderPages,
      TablesPageVue,
    },
    data() {
      return {
        tableHeaders: ["	صوره", " 	الاسم"],
        editLink: "/edit-group",
        viewLink: "/view-group",
      };
    },
  };
  </script>
  