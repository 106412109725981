import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useCompetitionEditStore = defineStore("competitionEdit", {
  state: () => ({
    competition: {},
    loading: false,
  }),
  actions: {
    async getCompetition(id) {
      try {
        const response = await axios.post(`fetch_competition_details`, {
          id: id,
        });
        this.competition = response.data.data;
        console.log(this.competition);
      } catch (error) {
        console.error(error);
      }
    },
    async updateCompetition(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (key === "image") {
            if (data[key] instanceof File) {
              formData.append("image", data[key]);
            }
          } else {
            formData.append(key, data[key]);
          }
        });
        const response = await axios.post("edit_competition", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.competition = response.data.data;
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Competition Updated Successfully",
          });
          await router.replace("/index-competion");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
