<template>
  <div class="slider">
    <header-pages
      title="الهشتاج"
      button="اضافة   "
      link="/add-hashtag"
    ></header-pages>
    <tables-page-vue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteBlogHashtag"
    />
    <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { useEmployeesStore } from "@/stores/employees/EmployeesStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useBlogHashtagStore } from "@/stores/BlogHashtag/BlogHashtagStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
export default {
  name: "GroupsIndex",
  components: {
    PaginationPage,
    HeaderPages,
    TablesPageVue,
  },
  data() {
    return {
      tableHeaders: ["Id", "الصورة", "الاسم", "الوصف"],
      editLink: "/edit-hashtag",
      viewLink: "/view-group",
    };
  },
  computed: {
    ...mapState(useBlogHashtagStore, {
      blogHashtag: (state) => state.blogHashtag,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.employees, "ssss");
      return this.blogHashtag.map((emp) => [
        emp.id,
        emp.image,
        emp.title,
        emp.description,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const curriculaStore = useEmployeesStore();
      curriculaStore.fetchEmployees(page);
    },
    async handleDeleteBlogHashtag(id) {
      const employeesStore = useBlogHashtagStore();
      console.log(id);

      await employeesStore.deleteBlogHashtag(id);
    },
  },

  async mounted() {
    const employeesStore = useBlogHashtagStore();
    await employeesStore.getBlogHashtag();
  },
};
</script>
