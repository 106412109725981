<template>
  <div class="moderators">
    <header-pages
      title="المشرفين"
      button="+ اضافة مشرف جديد "
      link="/add-moderators"
    ></header-pages>
    <div class="cards_moderators">
      <div class="card_moderators">
        <div class="user-one">
          <div class="img-contant">
            <div class="img">
              <img src="@/assets/photos/Rectangle 8917.png" alt="user1" />
            </div>
            <div class="contant-user">
              <h6>محمود علي</h6>
              <p>مجموعة العهد الحديث</p>
              <p><i class="fa-solid fa-phone"></i> 256825582</p>
              <p><i class="fa-solid fa-envelope"></i> someone@gmail.com</p>
              <p>
                <i class="fa-solid fa-calendar-days"></i> تاريخ الإضافة: 22 مايو
                2022
              </p>
            </div>
          </div>
          <div class="dropdown">
            <button
              class="btn btn-light dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="#">تعديل بيانات</a></li>
              <li><a class="dropdown-item" href="#"> حذف </a></li>
            </ul>
          </div>
        </div>
        <div class="user-one">
          <div class="img-contant">
            <div class="img">
              <img src="@/assets/photos/Rectangle 8917.png" alt="user1" />
            </div>
            <div class="contant">
              <h6>محمود علي</h6>
              <p>مجموعة العهد الحديث</p>
              <p><i class="fa-solid fa-phone"></i> 256825582</p>
              <p><i class="fa-solid fa-envelope"></i> someone@gmail.com</p>
              <p>
                <i class="fa-solid fa-calendar-days"></i> تاريخ الإضافة: 22 مايو
                2022
              </p>
            </div>
          </div>
          <div class="dropdown">
            <button
              class="btn btn-light dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="#">تعديل بيانات</a></li>
              <li><a class="dropdown-item" href="#"> حذف </a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card_moderators">
        <div class="user-one">
          <div class="img-contant">
            <div class="img">
              <img src="@/assets/photos/Rectangle 8917.png" alt="user1" />
            </div>
            <div class="contant">
              <h6>محمود علي</h6>
              <p>مجموعة العهد الحديث</p>
              <p><i class="fa-solid fa-phone"></i> 256825582</p>
              <p><i class="fa-solid fa-envelope"></i> someone@gmail.com</p>
              <p>
                <i class="fa-solid fa-calendar-days"></i> تاريخ الإضافة: 22 مايو
                2022
              </p>
            </div>
          </div>
          <div class="dropdown">
            <button
              class="btn btn-light dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="#">تعديل بيانات</a></li>
              <li><a class="dropdown-item" href="#"> حذف </a></li>
            </ul>
          </div>
        </div>
        <div class="user-one">
          <div class="img-contant">
            <div class="img">
              <img src="@/assets/photos/Rectangle 8917.png" alt="user1" />
            </div>
            <div class="contant">
              <h6>محمود علي</h6>
              <p>مجموعة العهد الحديث</p>
              <p><i class="fa-solid fa-phone"></i> 256825582</p>
              <p><i class="fa-solid fa-envelope"></i> someone@gmail.com</p>
              <p>
                <i class="fa-solid fa-calendar-days"></i> تاريخ الإضافة: 22 مايو
                2022
              </p>
            </div>
          </div>
          <div class="dropdown">
            <button
              class="btn btn-light dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li><a class="dropdown-item" href="#">تعديل بيانات</a></li>
              <li><a class="dropdown-item" href="#"> حذف </a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
  },
  data() {
    return {
      editLink: "/edit-group",
      viewLink: "/view-group",
    };
  },
};
</script>
