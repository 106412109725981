<template>
  <div class="courses">
    <header-pages title="الدورات" button="اضافة دورة" link="/add-course" />
    <tables-page-vue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteGroup"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useCoursesStore } from "@/stores/courses/CoursesStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
export default {
  name: "CoursesIndex",
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: [
        "ID",

        "اسم الدورة",
        "السنوات الدراسيه ",
        "الفصول الدراسيه ",
        "المنهج",
        "الاعاقات",
        "المراحل",
      ],
      editLink: "/edit-course",
      viewLink: "/view-course",
    };
  },
  computed: {
    ...mapState(useCoursesStore, {
      courses: (state) => state.courses,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      return this.courses.map((cor) => [
        cor.id,
        cor.name,
        cor.year?.title,
        cor.season?.title,
        cor.curriculum?.title,
        cor.disability_types?.map((dt) => dt.title).join(", "),
        cor.stages?.map((dt) => dt.title).join(", "),
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      this.paginationCurrent = page;
    },
    handleDeleteGroup(id) {
      const coursesStore = useCoursesStore();
      coursesStore.deleteCourse(id);
    },
  },

  mounted() {
    const coursesStore = useCoursesStore();
    coursesStore.fetchCourses();
  },
};
</script>

<style></style>
