<template>
  <header-pages
    title="المجموعات"
    button="اضافة مجموعة"
    link="/add-group"
  ></header-pages>
  <tables-page-vue
    :headers="tableHeaders"
    :rows="tableRows"
    :pages="tablePages"
    :showSelect="false"
    :editLink="editLink"
    :viewLink="viewLink"
    @delete="handleDeleteGroup"
  />
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useGroupsStore } from "@/stores/Groups/GroupsStore";
export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    TablesPageVue,
  },
  data() {
    return {
      useGroupsStore: useGroupsStore(),
      tableHeaders: [
        "ID",
        "اسم المجموعة",
        "البريد الالكتروني",
        "رقم الهاتف",
        "نوع المجموعة",
      ],
      editLink: "/edit-group",
      viewLink: "/view-group",
    };
  },

  mounted() {
    this.useGroupsStore.fetchGroups();
  },
};
</script>

<style></style>
