import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useBlogEdit = defineStore("blogEdit", {
  state: () => ({
    blog: {},
    blogsHashtag: [],
    blogsCategory: [],
  }),
  actions: {
    async updateBlog(data) {
      this.loading = true;
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            if (key === "image") {
              if (data[key] instanceof File) {
                formData.append("image", data[key]);
              }
            } else {
              formData.append(key, data[key]);
            }
          }
        });
        const response = await axios.post("/edit_blog", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.blog = response.data;
        this.loading = false;
        Swal.fire({
          icon: "success",
          title: "Blog added successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        await router.push("/index-blog");
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async getBlog(id) {
      try {
        const response = await axios.post("fetch_blog_details", {
          id: id,
        });
        this.blog = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getBlogsHashtag() {
      try {
        const response = await axios.get("/organization_fetch_blog_hashtags");
        this.blogsHashtag = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getBlogsCategory() {
      try {
        const response = await axios.get("/organization_fetch_blog_categories");
        this.blogsCategory = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
});
