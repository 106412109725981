import { defineStore } from "pinia";
import axios from "axios";
import swal from "sweetalert2";

export const useCoursesAddStore = defineStore("coursesAdd", {
  state: () => ({
    courses: [],
    Curricula: [],
    curriculum_id: [],
    Stages: [],
    stages_id: [],
  }),
  actions: {
    async fetchCurricula() {
      try {
        const response = await axios.post("organization_fetch_curriculums");
        if (response.data.status) {
          this.Curricula = response.data.data.data;
          this.curriculum_id = this.Curricula.map((Cur) => Cur.id);
        } else {
          console.error("Error fetching curricula:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },
    async fetchStages() {
      try {
        const response = await axios.post("organization_fetch_stages");
        if (response.data.status) {
          this.Stages = response.data.data.data;
          this.stages_id = this.Stages.map((stage) => stage.id);
        } else {
          console.error("Error fetching stages:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },

    async addCourse(courseData) {
      try {
        const formData = new FormData();
        Object.keys(courseData).forEach((key) => {
          if (Array.isArray(courseData[key])) {
            courseData[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            formData.append(key, courseData[key]);
          }
        });
        const response = await axios.post("add_course", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.data.status) {
          this.courses = response.data.data.data;
          console.log(this.courses);
          swal.fire({
            icon: "success",
            title: "Success",
            text: "Course Added Successfully",
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error(error);
        swal.fire({
          icon: "error",
          title: "Error",
          text: error.response.data.message,
        });
      }
    },
  },
});
