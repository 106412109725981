<template>
  <div class="employees-edit">
    <header-pages title="تعديل دورة" :showButton="false" link="/edit-courses" />
    <form action="" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <!-- <span class="error-feedback" v-if="v$.form.imageSrc.$error"
                >{{ v$.form.imageSrc.$errors[0].$message }}
              </span> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الدورة</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل أسم الدورة"
              width="100%"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> الفصول الدراسيه </label>
          <multiselect
            v-model="Classes_value"
            :options="ClassesOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            @update:modelValue="updateClasses"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> السنوات الدراسيه </label>
          <multiselect
            v-model="Courses_value"
            :options="CoursesOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            @update:modelValue="updateCourses"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Disapilty"> الاعاقات </label>
          <multiselect
            v-model="Disapility_value"
            :options="DisapilityOptions"
            :close-on-select="true"
            track-by="id"
            label="title"
            :multiple="true"
            @change="updateDisapility"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> المنهج </label>
          <multiselect
            v-model="Curricla_value"
            :options="CurriclaOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            @update:modelValue="updateCurricla"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Stages"> المراحل </label>
          <multiselect
            v-model="Stages_value"
            :options="StagesOptions"
            :close-on-select="true"
            track-by="id"
            label="title"
            :disabled="allcurricula"
            :multiple="true"
            @change="updateStages"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div style="display: flex">
            <input
              type="checkbox"
              v-model="allcurricula"
              @change="togglecurriculaSelect"
            />
            <label for="name"> المنهج كامل</label>
          </div>
          <multiselect
            v-model="curricula_value"
            :options="curriculaOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="true"
            @update:modelValue="updatecurricula"
          ></multiselect>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  name: "CoursesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      form: {
        imageSrc: "",
        image: "",
      },
      curricula_value: [],
      curriculaOptions: [
        {
          id: 1,
          title: "1",
        },
        {
          id: 2,
          title: "2",
        },
        {
          id: 3,
          title: "3",
        },
      ],
      allcurricula: false,
      Stages_value: [],
      StagesOptions: [
        {
          id: 1,
          title: "Stages 1 ",
        },
        {
          id: 2,
          title: "Stages 2 ",
        },
        {
          id: 3,
          title: "Stages 3 ",
        },
      ],
      Disapility_value: [],
      DisapilityOptions: [
        {
          id: 1,
          title: "Disapilty 1",
        },
        {
          id: 2,
          title: "Disapilty 2",
        },
        {
          id: 3,
          title: "Disapilty 3",
        },
      ],
      Curricla_value: [],
      CurriclaOptions: [
        {
          id: 1,
          title: " Curricla 1",
        },
        {
          id: 2,
          title: " Curricla 2",
        },
        {
          id: 3,
          title: " Curricla 3",
        },
      ],
      Courses_value: [],
      CoursesOptions: [
        {
          id: 1,
          title: "Course 1",
        },
        {
          id: 2,
          title: "Course 2",
        },
        {
          id: 3,
          title: "Course 3",
        },
      ],
      Classes_value: [],
      ClassesOptions: [
        {
          id: 1,
          title: "Classes 1",
        },
        {
          id: 2,
          title: "Classes 2",
        },
        {
          id: 3,
          title: "Classes 3",
        },
      ],
    };
  },
  methods: {
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.form.imageSrc = "";
    },
  },
};
</script>
