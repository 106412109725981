<template>
  <div class="add_parent">
    <header-pages
      title=" إضافة ولي امر جديد "
      :showButton="false"
      link="/groups"
    />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!parent.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
              style="margin-top: 0"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="parent.imageSrc" class="avatar-preview">
              <img :src="parent.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">اسم ولي امر </label>
          <div class="input">
            <input
              type="text"
              placeholder=" ادحل اسم ولي امر"
              v-model="parent.name"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> البريد الالكتروني </label>
          <div class="input">
            <input
              type="email"
              placeholder="ادخل البريد الالكتروني"
              v-model="parent.email"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> كلمة المرور </label>
          <div class="input">
            <input
              type="password"
              placeholder="كلمة المرور"
              v-model="parent.password"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تأكيد كلمة المرور</label>
          <div class="input">
            <input
              type="password"
              placeholder="تأكيد كلمة المرور"
              v-model="parent.password_confirmation"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date"> تاريخ الميلاد </label>
            <input v-model="parent.date_of_birth" type="date" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date"> العمر </label>
            <input v-model="parent.age" type="number" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تفاصيل العنوان </label>
          <div class="input">
            <input
              type="text"
              placeholder=" اكتب تفاصيل العنوان"
              v-model="parent.address"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> رقم الجوال </label>
          <div class="input">
            <input
              type="text"
              placeholder="رقم الجوال"
              v-model="parent.phone"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">فصيلة الدم</label>
          <multiselect
            v-model="parent.blood_type_id"
            :options="bloodOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختر المجموعه</label>
          <multiselect
            v-model="parent.group_ids"
            :options="groupOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الدوله</label>
          <multiselect
            v-model="parent.country_id"
            :options="countryptions"
            :close-on-select="false"
            track-by="id"
            label="title"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="identity_type">نوع الهوية</label>
          <div class="radio d-flex">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="parent.identity_type"
                value="0"
                type="radio"
                name="identity_type"
                id="flexRadioDefault5"
              />
              <label class="form-check-label" for="flexRadioDefault5">
                بطاقة
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="parent.identity_type"
                class="form-check-input"
                value="1"
                type="radio"
                name="identity_type"
                id="flexRadioDefault6"
              />
              <label class="form-check-label" for="flexRadioDefault6">
                اقامه
              </label>
            </div>
          </div>
          <div class="identitynumber">
            <label for="identity_number"> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                type="text"
                placeholder="ادخل رقم الهويه"
                v-model="parent.identity_number"
                id="identity_number"
              />
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="gender">نوع</label>
          <div class="radio d-flex">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="parent.gender"
                value="0"
                type="radio"
                name="gender"
                id="flexRadioDefault11"
              />
              <label class="form-check-label" for="flexRadioDefault11">
                ذكر
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="parent.gender"
                class="form-check-input"
                value="1"
                type="radio"
                name="gender"
                id="flexRadioDefault10"
              />
              <label class="form-check-label" for="flexRadioDefault10">
                انثي
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useParentsAddStore } from "@/stores/parent/parentStoreAdd";

export default {
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useParentsAddStore(),
      
      parent: {
        name: "",
        email: "",
        phone: "",
        password: "",
        identity_type: 0,
        identity_number: "",
        date_of_birth: "",
        password_confirmation: "",
        age: "",
        gender: 0,
        address: "",
        image: null,
        imageSrc: "",
        blood_type_id: null,
        group_ids: [],
        country_id: null,
        type: 1,
      },
      country_valus: null,
      group_valus: null,
      bloods_valus: null,
      groupOptions: [],
      countryptions: [],
      bloodOptions: [],
    };
  },
  methods: {
    updatecountry() {
      this.parent.country_id =
        this.country_valus && this.country_valus.id
          ? this.country_valus.id
          : null;
      console.log(this.parent.country_id);
    },
    updateblood() {
      this.parent.blood_type_id =
        this.bloods_valus && this.bloods_valus.id ? this.bloods_valus.id : null;
      console.log(this.parent.blood_type_id);
    },

    removeImage() {
      this.parent.image = null;
      this.parent.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.parent.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.parent.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    async submitForm() {
      this.parent.blood_type_id = this.parent.blood_type_id.id;
      this.parent.group_ids = this.parent.group_ids.map((item) => item.id);
      this.parent.country_id = this.parent.country_id.id;
      await this.store.addParents(this.parent);
      //   this.$router.go(-1);
    },
    async fetchcountries() {
      await this.store.fetchcountries();
      this.countryptions = this.store.countries;
    },
    async fetchbolood() {
      await this.store.fetchblood();
      this.bloodOptions = this.store.bloods;
    },
    async fetchgroup() {
      await this.store.fetchgroup();
      this.groupOptions = this.store.groups;
    },
  },
  mounted() {
    this.fetchcountries();
    this.fetchbolood();
    this.fetchgroup();
  },
};
</script>
