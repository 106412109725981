<template>
    <div class="edit-successpartners">
      <HeaderPages title="شركاء النجاح" :showButton="false" />
      <form @submit.prevent="submitForm">
        <div class="row">
          <div class="col-lg-10 col-md-12 col-12">
            <div class="avatar-uploader">
              <label for="avatar">اضافة صورة</label>
              <input
                type="file"
                id="avatar"
                @change="handleFileChange"
                accept="image/*"
                ref="fileInput"
                style="display: none"
              />
              <div
                v-if="!form.imageSrc"
                class="upload-icon"
                @click="triggerFileInput"
              >
                <i class="fa fa-camera"></i>
                <span>اختيار صورة</span>
              </div>
              <div v-if="form.imageSrc" class="avatar-preview">
                <img :src="form.imageSrc" alt="Avatar Preview" />
                <i class="fa fa-times delete-icon" @click="removeImage"></i>
              </div>
            </div>
          </div>
  
          <div class="col-lg-6 col-md-12 col-12">
            <label for="name"> الاسم</label>
            <div class="input">
              <input type="text" id="name" placeholder="اكتب هنا " />
            </div>
          </div>
        </div>
  
        <div class="all-btn">
          <button type="submit" class="save">حفظ</button>
          <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import HeaderPages from "@/components/headerpages/HeaderPages.vue";
  import Multiselect from "vue-multiselect";
  import "vue-multiselect/dist/vue-multiselect.css";
  
  export default {
    name: "EmployeesAdd",
    components: {
      HeaderPages,
      Multiselect,
    },
    data() {
      return {
        form: {
          imageSrc: "",
        },
        CurriculaOptions: [],
      };
    },
  
    methods: {
      // add img 1
      handleFileChange(event) {
        const file = event.target.files[0];
        if (file) {
          this.form.image = file;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.form.imageSrc = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      },
      triggerFileInput() {
        this.$refs.fileInput.click();
      },
      removeImage() {
        this.form.image = null;
        this.form.imageSrc = "";
      },
      // add img 1
    },
  };
  </script>
  
  <style scoped>
  .upload-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #ccc;
    padding: 10px;
    margin-top: 10px;
  }
  
  .avatar-preview {
    position: relative;
    margin-top: 10px;
  }
  
  .avatar-preview img {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
  }
  
  .delete-icon {
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(232 240 254);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    color: red;
    font-size: 20px;
  }
  
  .certificates-preview {
    display: flex;
    flex-wrap: wrap;
  }
  
  .certificate-image {
    position: relative;
    margin: 10px;
  }
  
  .certificate-image img {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
  }
  .checkbox {
    margin: 1rem 0;
  }
  </style>
  