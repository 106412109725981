<template>
  <div class="Department">
    <header-pages
      title="الأقسام"
      button="اضافة   "
      link="/add-department"
    ></header-pages>
    <tables-page-vue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteBlogsCategory"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { useBlogsCategory } from "@/stores/BlogCategory/BlogCategoryStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
export default {
  name: "GroupsIndex",
  components: {
    PaginationPage,
    HeaderPages,
    TablesPageVue,
  },
  data() {
    return {
      tableHeaders: ["ID", "العنوان"],
      editLink: "/edit-department",
      viewLink: "/edit-department",
    };
  },
  computed: {
    ...mapState(useBlogsCategory, {
      blogsCategory: (state) => state.blogsCategory,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      return this.blogsCategory.map((exa) => [exa.id, exa.title]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const curriculaStore = useBlogsCategory();
      curriculaStore.getBlogsCategory(page);
    },
    async handleDeleteBlogsCategory(id) {
      const blogsCategoryStore = useBlogsCategory();
      await blogsCategoryStore.deleteBlogCategory(id);
    },
  },

  async mounted() {
    const blogsCategoryStore = useBlogsCategory();
    await blogsCategoryStore.getBlogsCategory();
  },
};
</script>
