import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useEmployeesEditStore = defineStore("employeesEdit", {
  state: () => ({
    employees: [],
    employee: [],
    postion: [],
    job_type: "",
    images: [],
  }),
  actions: {
    async fetchEmployee(id) {
      try {
        const response = await axios.post("fetch_employee_details", { id });
        if (response.data.status === true) {
          this.employee = response.data.data;
          this.job_type = this.employee.job_type;
          console.log(this.job_type, "employee");
          this.images = this.employee.images.map((im) => ({
            image: im.image,
            id: im.id,
          }));
        } else {
          throw new Error("Failed to fetch employee data");
        }
      } catch (error) {
        console.error(error);
        // Handle error (e.g., show a notification)
      }
    },
    // Action to remove an image
    removeImage(index) {
      this.images.splice(index, 1); // This will update the store
    },
    async fetchPositions() {
      try {
        const response = await axios.get("organization_fetch_job_types");
        if (response.data.status === true) {
          this.postion = response.data.data;
          this.job_type_id = this.postion.map((pos) => pos.id);
          console.log(this.postion, "sssss");
          console.log(this.job_type_id, "sssss");
        } else {
          console.log("Error fetching disabilities");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async updateEmployees(id, updatedData) {
      try {
        const formData = new FormData();
        formData.append("id", id);

        Object.keys(updatedData).forEach((key) => {
          if (Array.isArray(updatedData[key])) {
            // If the property is an array, append each item with the same key
            updatedData[key].forEach((item) => {
              formData.append(`${key}[]`, item); // Use key[] for array values
            });
          } else {
            // Append non-array values normally
            formData.append(key, updatedData[key]);
          }
        });

        // Log FormData entries
        for (const [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`, "FormData");
        }

        const response = await axios.post("edit_employee", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.data.status === true) {
          this.employees = response.data.data.data; // Fix: Change this.response to response
          Swal.fire("Success", "Employee has been updated.", "success");
        } else {
          Swal.fire("Error", "Failed to update employee.", "error");
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
