import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth/AuthStore";
import Login from "@/views/dashboard/Auth/LoginPage.vue";
import Forget from "@/views/dashboard/Auth/ForgetPassword.vue";
import CodeReset from "@/views/dashboard/Auth/CodeReset.vue";
import Dashboard from "@/views/dashboard/dashboard.vue";
import HomeView from "@/views/dashboard/Home/HomeView.vue";
import EmployeesAdd from "@/views/dashboard/employees/EmployeesAdd.vue";
import EmployeesEdit from "@/views/dashboard/employees/EmployeesEdit.vue";
import EmployeesIndex from "@/views/dashboard/employees/EmployeesIndex.vue";
import GroupsIndex from "@/views/dashboard/groups/GroupsIndex.vue";
import GroupsAdd from "@/views/dashboard/groups/GroupsAdd.vue";
import GroupsEdit from "@/views/dashboard/groups/GroupsEdit.vue";
import CoursesIndex from "@/views/dashboard/courses/CoursesIndex.vue";
import CoursesAdd from "@/views/dashboard/courses/CoursesAdd.vue";
import CoursesEdit from "@/views/dashboard/courses/CoursesEdit.vue";
import ExamsIndex from "@/views/dashboard/exams/ExamsIndex.vue";
import ExamsAdd from "@/views/dashboard/exams/ExamsAdd.vue";
import ExamsEdit from "@/views/dashboard/exams/ExamsEdit.vue";
import StudentIndex from "@/views/dashboard/student/StudentIndex.vue";
import AddStudentt from "@/views/dashboard/student/AddStudentt.vue";
import EditStudentt from "@/views/dashboard/student/EditStudent.vue";
import TeacherIndex from "@/views/dashboard/teacher/TeacherIndex.vue";
import AddTeacher from "@/views/dashboard/teacher/AddTeacher.vue";
import IndexParent from "@/views/dashboard/parent/IndexParent.vue";
import EditParent from "@/views/dashboard/parent/editParent.vue";
import AddParent from "@/views/dashboard/parent/addParent.vue";
import EditTeacher from "@/views/dashboard/teacher/EditTeacher.vue";
import IndexModerators from "@/views/dashboard/moderators/IndexModerators.vue";
import AddModerators from "@/views/dashboard/moderators/AddModerators.vue";
import EditModerators from "@/views/dashboard/moderators/EditModerators.vue";
import IndexSlider from "@/views/dashboard/slider/IndexSlider.vue";
import EditSlider from "@/views/dashboard/slider/EditSlider.vue";
import AddSlider from "@/views/dashboard/slider/AddSlider.vue";
import WhoWe from "@/views/dashboard/whowe/WhoWe.vue";
import IndexSuccesspartners from "@/views/dashboard/Successpartners/IndexSuccesspartners.vue";
import AddSuccesspartners from "@/views/dashboard/Successpartners/AddSuccesspartners.vue";
import EditSuccesspartners from "@/views/dashboard/Successpartners/EditSuccesspartners.vue";
import IndexServes from "@/views/dashboard/serves/IndexServes.vue";
import Addserves from "@/views/dashboard/serves/Addserves.vue";
import EditServes from "@/views/dashboard/serves/EditServes.vue";
import AppTeacher from "@/views/dashboard/appteacher/AppTeacher.vue";
import Appparent from "@/views/dashboard/appparent/Appparent.vue";
import AppStudent from "@/views/dashboard/appstudent/AppStudent.vue";

import IndexBlog from "@/views/dashboard/blog/IndexBlog.vue";
import AddBlog from "@/views/dashboard/blog/AddBlog.vue";
import EditBlog from "@/views/dashboard/blog/EditBlog.vue";
import IndexDepartment from "@/views/dashboard/Department/IndexDepartment.vue";
import AddDepartment from "@/views/dashboard/Department/AddDepartment.vue";
import EditDepartment from "@/views/dashboard/Department/EditDepartment.vue";
import IndexHashtag from "@/views/dashboard/Hashtag/IndexHashtag.vue";
import AddHashtag from "@/views/dashboard/Hashtag//AddHashtag.vue";
import EditHashtag from "@/views/dashboard/Hashtag/EditHashtag.vue";
import IndexComplaints from "@/views/dashboard/Complaints/IndexComplaints.vue";
import IndexCompetion from "@/views/dashboard/competions/IndexCompetion.vue";
import AddCompetion from "@/views/dashboard/competions/AddCompetion.vue";
import EditCompetion from "@/views/dashboard/competions/EditCompetion.vue";
import Indexelectroniclibrary from "@/views/dashboard/electroniclibrary/IndexElectroniclibrary.vue";
import addElectroniclibrary from "@/views/dashboard/electroniclibrary/addElectroniclibrary.vue";
import editElectroniclibrary from "@/views/dashboard/electroniclibrary/editElectroniclibrary.vue";
import QuestionBanksIndex from "@/views/dashboard/question_banks/QuestionBanksIndex.vue";
import AddQuestionBanks from "@/views/dashboard/question_banks/QuestionBanksAdd.vue";
import EditQuestionBanks from "@/views/dashboard/question_banks/QuestionBanksEdit.vue";
const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/forget-password",
    name: "forget",
    component: Forget,
    meta: { guest: true },
  },
  {
    path: "/Code",
    name: "Code",
    component: CodeReset,
    meta: { guest: true },
  },
  {
    path: "/dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
      },
      {
        path: "/employees",
        name: "Employees",
        component: EmployeesIndex,
      },
      {
        path: "/add-employee",
        name: "EmployeesAdd",
        component: EmployeesAdd,
      },
      {
        path: "/edit-employee/:id",
        name: "EmployeesEdit",
        component: EmployeesEdit,
      },
      {
        path: "/groups",
        name: "Groups",
        component: GroupsIndex,
      },
      {
        path: "/add-group",
        name: "GroupsAdd",
        component: GroupsAdd,
      },
      {
        path: "/edit-group/:id",
        name: "GroupsEdit",
        component: GroupsEdit,
      },
      {
        path: "/courses",
        name: "Courses",
        component: CoursesIndex,
      },
      {
        path: "/add-course",
        name: "CoursesAdd",
        component: CoursesAdd,
      },
      {
        path: "/edit-courses/:id",
        name: "CoursesEdit",
        component: CoursesEdit,
      },
      {
        path: "/exams",
        name: "exams",
        component: ExamsIndex,
      },
      {
        path: "/exams-add",
        name: "exams-add",
        component: ExamsAdd,
      },
      {
        path: "/exams-edit/:id",
        name: "exams-edit",
        component: ExamsEdit,
      },
      {
        path: "/student-index",
        name: "student-index",
        component: StudentIndex,
      },
      {
        path: "/add-student",
        name: "add-student",
        component: AddStudentt,
      },
      {
        path: "/edit-student/:id",
        name: "edit-student",
        component: EditStudentt,
      },
      {
        path: "/teacher-index",
        name: "teacher-index",
        component: TeacherIndex,
      },
      {
        path: "/add-teacher",
        name: "add-teacher",
        component: AddTeacher,
      },
      {
        path: "/edit-teacher/:id",
        name: "edit-teacher",
        component: EditTeacher,
      },
      {
        path: "/index-parent",
        name: "index-parent",
        component: IndexParent,
      },
      {
        path: "/edit-parent/:id",
        name: "edit-parent",
        component: EditParent,
      },
      {
        path: "/add-parent",
        name: "add-parent",
        component: AddParent,
      },
      {
        path: "/index-moderators",
        name: "index-moderators",
        component: IndexModerators,
      },
      {
        path: "/add-moderators",
        name: "add-moderators",
        component: AddModerators,
      },
      {
        path: "/edit-moderators/:id",
        name: "edit-moderators",
        component: EditModerators,
      },
      {
        path: "/index-slider",
        name: "index-slider",
        component: IndexSlider,
      },
      {
        path: "/add-slider",
        name: "add-slider",
        component: AddSlider,
      },

      {
        path: "/edit-slider/:id",
        name: "edit-slider",
        component: EditSlider,
      },
      {
        path: "/who-we",
        name: "who-we",
        component: WhoWe,
      },
      {
        path: "/index-successpartners",
        name: "index-successpartners",
        component: IndexSuccesspartners,
      },
      {
        path: "/add-successpartners",
        name: "add-successpartners",
        component: AddSuccesspartners,
      },
      {
        path: "/edit-successpartners/:id",
        name: "edit-successpartners",
        component: EditSuccesspartners,
      },

      {
        path: "/index-serves",
        name: "index-serves",
        component: IndexServes,
      },
      {
        path: "/add-serves",
        name: "add-serves",
        component: Addserves,
      },
      {
        path: "/edit-serves/:id",
        name: "edit-serves",
        component: EditServes,
      },
      {
        path: "/app-teacher",
        name: "app-teacher",
        component: AppTeacher,
      },
      {
        path: "/app-parent",
        name: "app-parent",
        component: Appparent,
      },
      {
        path: "/app-student",
        name: "app-student",
        component: AppStudent,
      },

      {
        path: "/index-blog",
        name: "index-blog",
        component: IndexBlog,
      },
      {
        path: "/add-blog",
        name: "add-blog",
        component: AddBlog,
      },
      {
        path: "/edit-blog/:id",
        name: "edit-blog",
        component: EditBlog,
      },
      {
        path: "/index-department",
        name: "index-department",
        component: IndexDepartment,
      },
      {
        path: "/add-department",
        name: "add-department",
        component: AddDepartment,
      },
      {
        path: "/edit-department/:id",
        name: "edit-department",
        component: EditDepartment,
      },
      {
        path: "/index-hashtag",
        name: "index-hashtag",
        component: IndexHashtag,
      },
      {
        path: "/add-hashtag",
        name: "add-hashtag",
        component: AddHashtag,
      },
      {
        path: "/edit-hashtag/:id",
        name: "edit-hashtag",
        component: EditHashtag,
      },
      {
        path: "/index-complaints",
        name: "index-complaints",
        component: IndexComplaints,
      },
      {
        path: "/index-competion",
        name: "index-competion",
        component: IndexCompetion,
      },
      {
        path: "/add-competion",
        name: "add-competion",
        component: AddCompetion,
      },
      {
        path: "/edit-competion/:id",
        name: "edit-complaints",
        component: EditCompetion,
      
      },
      {
        path: "/index-electroniclibrary",
        name: "index-electroniclibrary",
        component: Indexelectroniclibrary, // Make sure this matches the import statement
      },
      {
        path: "/add-electroniclibrary",
        name: "add-electroniclibrary",
        component: addElectroniclibrary, // Make sure this matches the import statement
      },
      {
        path: "/edit-electroniclibrary/:id",
        name: "edit-electroniclibrary",
        component: editElectroniclibrary, // Make sure this matches the import statement
      },
      {
        path: "/index-question-banks",
        name: "index-question-banks",
        component: QuestionBanksIndex,
      },
      {
        path: "/add-question-banks",
        name: "add-question-banks",
        component: AddQuestionBanks,
      },
      {
        path: "/edit-question-banks/:id",
        name: "edit-question-banks",
        component: EditQuestionBanks,
      },
    ],
  },
  {
    path: "/",
    redirect: "/login", // Redirect to login if not authenticated
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global Authentication Guard
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  if (to.meta.requiresAuth && !authStore.token) {
    // If trying to access a protected route without being authenticated, redirect to login
    next({ name: "login" });
  } else if (to.meta.guest && authStore.token) {
    // If already authenticated and trying to access a guest route, redirect to dashboard
    next({ name: "home" });
  } else {
    next(); // Proceed to the route
  }
});

export default router;
