import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useBlogHashtagStore = defineStore("blogHashtag", {
  state: () => ({
    blogHashtag: [],
  }),
  actions: {
    async getBlogHashtag() {
      this.blogHashtagLoading = true;

      try {
        const response = await axios.post("fetch_blog_hashtags");
        this.blogHashtag = response.data.data.data;
        this.blogHashtagLoading = false;
      } catch (error) {
        console.log(error);
        this.blogHashtagLoading = false;
      }
    },

    async deleteBlogHashtag(id) {
      const result = await Swal.fire({
        title: "هل انتا متاكد من عملية المسح?",
        text: "لن تتمكن من التراجع عن هذا!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، احذفه!",
      });

      if (result.isConfirmed) {
        await axios.post(`delete_blog_hashtag`, { id: id });
        this.blogHashtag = this.blogHashtag.filter((emp) => emp.id !== id);
        Swal.fire("تم الحذف!", "تم حذف الأمتحان", "success");
      }
    },
  },
});
