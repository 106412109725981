import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useGroupsAddStore = defineStore("groupsAdd", { 
  state: () => ({
    groups: [],
    courses: [],
    teachers: [],
    courseContents: [],
    disabilities: [],
    days: [],
  }),
  actions: {
    async fetchCourses() {
      const response = await axios.post("fetch_courses");
      if (response.data.status === true) {
        this.courses = response.data.data.data;
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchTeachers() {
      const response = await axios.post("fetch_teachers");
      if (response.data.status === true) {
        this.teachers = response.data.data.data;
      } else {
        console.log("Error fetching disabilities.");
      }
    },

    async fetchDisabilities() {
      const response = await axios.post("organization_fetch_disabilities");
      if (response.data.status === true) {
        this.disabilities = response.data.data.data;
      } else {
        console.log("Error fetching disabilities.");
      }
    },

    async fetchDays() {
      const response = await axios.post("fetch_days");
      if (response.data.status === true) {
        this.days = response.data.data;
        // console.log(response.data.data, "days");
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async fetchCourseContent() {
      const response = await axios.post("organization_fetch_stages");
      if (response.data.status === true) {
        this.courseContents = response.data.data.data;
      } else {
        console.log("Error fetching disabilities.");
      }
    },
    async addGroups(groupData) {
      try {
        // const formData = new FormData();
        // // Append each property to FormData
        // Object.keys(groupData).forEach((key) => {
        //   if (Array.isArray(groupData[key])) {
        //     // If the property is an array, append each item with the same key
        //     groupData[key].forEach((item) => {
        //       formData.append(`${key}[]`, item); // Use key[] for array values
        //     });
        //   } else {
        //     // Append non-array values normally
        //     formData.append(key, groupData[key]);
        //   }
        // });

        // // Log FormData entries
        // for (const [key, value] of formData.entries()) {
        //   console.log(`${key}: ${value}`, "FormData");
        //   console.log(key, "sasa");
        // }

        // Make the API request
        const response = await axios.post("add_group", groupData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Log response data
        console.log("API Response:", response.data);

        if (response.data.status) {
          this.Curricula.push(response.data);
          Swal.fire(
            "Success",
            "Steps have been saved successfully.",
            "success"
          );
        } else {
          Swal.fire(
            "Error",
            "There was a problem saving the steps: " + response.data.message,
            "error"
          );
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
