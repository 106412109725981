<template>
  <div class="employees">
    <HeadersPages
      title="المكتبات الالكترونية"
      button="+ اضافة المكتبات الالكترونية"
      link="/add-electroniclibrary"
    />
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="deletelibrary"
      :ismaster="ismaster"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useLibraryStore } from "@/stores/librarires/libraryStoreIndex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: [
        "ID",
        "الصور",
        "اسم الطالب",
        "يالبريد الالكتروني",
        "رقم الهاتف",
      ],
      editLink: "/edit-electroniclibrary",
      viewLink: "/view-electroniclibrary",
    };
  },
  computed: {
    ...mapState(useLibraryStore, {
      library: (state) => state.library,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.library, "ssss");
      return this.library.map((emp) => [
        emp.id,
        emp.image,
        emp.name,
        emp.email,
        emp.phone,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const libraryStore = useLibraryStore();
      libraryStore.fetchlibrary(page);
    },
    async deletelibrary(id) {
      const libraryStore = useLibraryStore();
      console.log(id);

      await libraryStore.deletelibrary(id);
    },
  },

  async mounted() {
    const libraryStore = useLibraryStore();
    await libraryStore.fetchlibrary();
  },
};
</script>
