import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useBlogsCategory = defineStore("blogCategory", {
  state: () => ({
    blogsCategory: [],
  }),
  actions: {
    async getBlogsCategory() {
      try {
        const response = await axios.post("fetch_blog_categories");
        this.blogsCategory = response.data.data.data;
        // console.log(this.blogsCategory);
      } catch (error) {
        console.log(error);
      }
    },
    async deleteBlogCategory(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post(`delete_blog_category`, { id: id });
          this.blogsCategory = this.blogsCategory.filter(
            (emp) => emp.id !== id
          );
          Swal.fire("تم الحذف!", "تم حذف الأمتحان", "success");
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
});
