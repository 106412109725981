import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useBlogs = defineStore("blogs", {
  state: () => ({
    blogs: [],
  }),

  actions: {
    async getBlogs() {
      try {
        const response = await axios.post("/fetch_blogs");
        this.blogs = response.data.data.data;
      } catch (error) {
        console.log(error);
      }
    },

    async deleteBlog(id) {
      const result = await Swal.fire({
        title: "هل انتا متاكد من عملية المسح?",
        text: "لن تتمكن من التراجع عن هذا!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، احذفه!",
      });

      if (result.isConfirmed) {
        await axios.post(`delete_blog`, { id: id });
        this.blogs = this.blogs.filter((emp) => emp.id !== id);
        Swal.fire("تم الحذف!", "تم حذف الأمتحان", "success");
      }
    },
  },
});
