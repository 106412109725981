<template>
  <div class="employees">
    <HeadersPages
      title="اولاياء الامور"
      button="+ اضافة ولي الامر"
      link="/add-parent"
    />
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="deleteParents"
      :ismaster="ismaster"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useParentsStore } from "@/stores/parent/parentindex";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: [
        "ID",
        "الصور",
        "اسم الطالب",
        "يالبريد الالكتروني",
        "رقم الهاتف",
      ],
      editLink: "/edit-parent",
      viewLink: "/view-parent",
    };
  },
  computed: {
    ...mapState(useParentsStore, {
      Parents: (state) => state.Parents,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.parents, "ssss");
      return this.Parents.map((emp) => [
        emp.id,
        emp.image,
        emp.name,
        emp.email,
        emp.phone,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const parentsStore = useParentsStore();
      parentsStore.fetchParents(page);
    },
    async deleteParents(id) {
      const parentsStore = useParentsStore();
      console.log(id);

      await parentsStore.deleteParents(id);
    },
  },

  async mounted() {
    const parentsStore = useParentsStore();
    await parentsStore.fetchParents();
  },
};
</script>
