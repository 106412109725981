<template>
  <HeadersPages
    title="بنك الاسئلة"
    button="+ اضافة بنك الاسئلة"
    link="/add-question-banks"
  />
  <TablesPageVue
    :headers="tableHeaders"
    :rows="tableRows"
    :pages="tablePages"
    :showSelect="false"
    :editLink="editLink"
    :viewLink="viewLink"
    @delete="handleDeleteQuestionBanks"
    :ismaster="ismaster"
  />
  <PaginationPage
    :currentPage="paginationCurrent"
    :totalPages="paginationLast"
    @page-changed="handlePageChange"
  />
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
// import { useQuestionBanksStore } from "@/stores/question_banks/QuestionBanksStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import { useQuestionsBankStore } from "@/stores/question_bank/QuestionBankStore";
import { useExamsStore } from "@/stores/exams/ExamsStore";
export default {
  name: "QuestionBanksIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: ["ID", "السؤال", "النوع", "الدرجة", "مقرر", "الموسم"],
      editLink: "/edit-question-banks",
      viewLink: "/question-banks-view/",
    };
  },
  computed: {
    ...mapState(useQuestionsBankStore, {
      questions_bank: (state) => state.questions_bank,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      return this.questions_bank.map((exa) => [
        exa.id,
        exa.question,
        exa.type,
        exa.degree,
        exa.curriculum.title,
        exa.season.title,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      this.$store.commit("setPaginationCurrent", page);
      this.$store.dispatch("getQuestionBanks");
    },
    async handleDeleteQuestionBanks(id) {
      const store = useQuestionsBankStore();
      await store.deleteQuestion(id);
    },
  },
  async mounted() {
    const store = useQuestionsBankStore();
    await store.fetchQuestionsBank();
  },
};
</script>

<style scoped></style>
