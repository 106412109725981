<template>
    <div class="add-slider">
      <HeaderPages title="من نحن" :showButton="false" />
      <form @submit.prevent="submitForm">
        <div class="col-lg-6 col-md-12 col-12">
            <label for="name"> عنوان</label>
            <div class="input">
              <input type="text" id="name" placeholder="اكتب هنا " />
            </div>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <label for="name"> الوصف</label>
            <br />
            <textarea id="w3review" name="w3review" rows="4" cols="75"></textarea>
          </div>
          <div class="col-lg-6 col-md-12 col-12">
            <label for="name"> فديو لينك</label>
            <div class="input">
              <input type="text" id="name" placeholder="اكتب هنا " />
            </div>
          </div>
        <div class="row">
          <div v-for="(image, index) in form.images" :key="index" class="col-lg-6 col-md-6 col-12">
            <div class="contant-uploader">
              <div class="avatar-uploader">
                <label for="avatar"> * اضافة صورة للسليدر</label>
                <input
                  type="file"
                  :id="'avatar' + index"
                  @change="handleFileChange($event, index)"
                  accept="image/*"
                  ref="fileInput"
                  style="display: none"
                />
                <div v-if="!image.src" class="upload-icon" @click="triggerFileInput(index)">
                  <i class="fa fa-camera"></i>
                  <span>اختيار صورة</span>
                </div>
                <div v-if="image.src" class="avatar-preview">
                  <img :src="image.src" alt="Avatar Preview" />
                  <i class="fa fa-times delete-icon" @click="removeImage(index)"></i>
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <label for="title"> عنوان</label>
                <div class="input">
                  <input type="text" id="title" placeholder="اكتب هنا " />
                </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                <label for="description"> الوصف</label>
                <br />
                <textarea id="description" name="description"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="all-btn">
          <button type="submit" class="save">حفظ</button>
          <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import HeaderPages from "@/components/headerpages/HeaderPages.vue";
  
  export default {
    name: "EmployeesAdd",
    components: {
      HeaderPages,
    },
    data() {
      return {
        form: {
          images: [
            { src: "", file: null },
            { src: "", file: null },
            { src: "", file: null },
            { src: "", file: null },
          ],
        },
      };
    },
    methods: {
      handleFileChange(event, index) {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.form.images[index].src = e.target.result;
            this.form.images[index].file = file;
          };
          reader.readAsDataURL(file);
        }
      },
      triggerFileInput(index) {
        this.$refs.fileInput[index].click();
      },
      removeImage(index) {
        this.form.images[index].src = "";
        this.form.images[index].file = null;
      },
    },
  };
  </script>
  
  <style scoped>
  .upload-icon {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #ccc;
    padding: 10px;
    margin-top: 10px;
  }
  
  .avatar-preview {
    position: relative;
    margin-top: 10px;
  }
  
  .avatar-preview img {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
  }
  
  .delete-icon {
    position: absolute;
    top: 0;
    right: 0;
    background: rgb(232 240 254);
    border-radius: 50%;
    padding: 5px;
    cursor: pointer;
    color: red;
    font-size: 20px;
  }
  
  .contant-uploader {
    background-color: rgb(6 121 126 / 7%);
    padding: 1rem 1rem;
    margin: 1rem 0;
    border-radius: 15px;
  }
  
  textarea {
    width: 100%;
    border-radius: 10px;
  }
  
  .contant-uploader textarea {
    width: 100%;
    height: 100px;
    border-radius: 10px;
  }
  </style>
  