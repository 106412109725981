import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";

export const uselibraryAddStore = defineStore("libraryAdd", {
  state: () => {
    return {};
  },
  actions: {
    async addlibrary(data) {
      try {
        const formData = new FormData();
        console.log(data, "data");

        // Loop through data to populate FormData
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
          } else {
            // Skip imageSrc to avoid sending it

            // Append other fields
            formData.append(key, data[key]);
          }
        });

        // Send the FormData to the API
        const response = await axios.post("add_library", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          router.push("/index-electroniclibrary");
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
