import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useQuestionBankEditStore = defineStore("question_bank_edit", {
  state: () => ({
    question_bank: {},
    seasons: [],
    curriculums: [],
  }),
  actions: {
    async getQuestion(id) {
      try {
        const response = await axios.post(`fetch_question_bank_details`, {
          id: id,
        });
        this.question_bank = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async updateQuestion(questionData) {
      try {
        const response = await axios.post("edit_question_bank", questionData);
        if (response.data.status === true) {
          Swal.fire(
            "Success",
            "Question has been updated successfully.",
            "success"
          );
        } else {
          throw new Error("Failed to update question");
        }
      } catch (error) {
        console.error(error);
        Swal.fire(
          "Error",
          "There was an error updating the question.",
          "error"
        );
      }
    },
    async getSeasons() {
      try {
        const response = await axios.get("organization_fetch_seasons");
        this.seasons = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getCurriculums() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        this.curriculums = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
