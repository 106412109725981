<template>
  <div class="employees-add">
    <HeaderPages title="اضافة موظف" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label>صور الشهادات</label>
          <input
            type="file"
            @change="handleCertificatesChange"
            accept="image/*"
            multiple
            ref="certificatesInput"
            style="display: none"
          />
          <div class="upload-icon" @click="triggerCertificatesInput">
            <i class="fa fa-file-image-o"></i>
            <span>اختيار صور</span>
          </div>
          <div class="certificates-preview">
            <div
              v-for="(src, index) in form.certificateImagesSrc"
              :key="index"
              class="certificate-image"
            >
              <img :src="src" alt="Certificate Preview" />
              <i
                class="fa fa-times delete-icon"
                @click="removeCertificate(index)"
              ></i>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الموظف</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل أسم الموظف"
              v-model="form.name"
            />
            <span class="error-feedback" v-if="v$.form.name.$error">{{
              v$.form.name.$errors[0].$message
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">الحاله الأجتماعيه</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="form.marital_status"
                value="1"
                type="radio"
                name="marrid"
                id="flexRadioDefault3"
              />
              <label class="form-check-label" for="flexRadioDefault3">
                متزوج
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="form.marital_status"
                class="form-check-input"
                value="0"
                type="radio"
                name="notmarrid"
                id="flexRadioDefault4"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault4">
                اعزب
              </label>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">نوع الهوية</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="form.identity_type"
                value="0"
                type="radio"
                name="card"
                id="flexRadioDefault5"
              />
              <label class="form-check-label" for="flexRadioDefault5">
                بطاقة
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="form.identity_type"
                class="form-check-input"
                value="1"
                type="radio"
                name="stay"
                id="flexRadioDefault6"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault6">
                اقامه
              </label>
            </div>
          </div>
          <div class="identitynumber">
            <label for=""> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                type="text"
                placeholder="ادخل الرقم الهويه"
                v-model="form.identity_number"
              />
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ الميلاد </label>
          <div class="input">
            <input
              type="date"
              placeholder="ادخل تاريخ الميلاد"
              v-model="form.date_of_birth"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="phone">رقم الهاتف</label>
          <div class="input">
            <input
              type="number"
              id="phone"
              placeholder="أدخل رقم الهاتف"
              v-model="form.phone"
              class="no-spinner"
            />
            <span class="error-feedback" v-if="v$.form.phone.$error">{{
              v$.form.phone.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="email">البريد الالكتروني</label>
          <div class="input">
            <input
              type="email"
              id="email"
              placeholder="أدخل البريد الالكتروني"
              v-model="form.email"
            />
            <span class="error-feedback" v-if="v$.form.email.$error">{{
              v$.form.email.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="password">الرقم السري</label>
          <div class="input">
            <input
              type="password"
              id="password"
              placeholder="أدخل الرقم السري"
              v-model="form.password"
            />
            <span class="error-feedback" v-if="v$.form.password.$error">{{
              v$.form.password.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="gender">النوع</label>
          <div class="radio">
            <div class="form-check">
              <input
                class="form-check-input"
                v-model="form.gender"
                value="1"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                ذكر
              </label>
            </div>
            <div class="form-check">
              <input
                v-model="form.gender"
                class="form-check-input"
                value="2"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked
              />
              <label class="form-check-label" for="flexRadioDefault2">
                أنثي
              </label>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="age"> العمر </label>
          <div class="input">
            <input
              type="number"
              id="age"
              placeholder="أدخل العمر"
              v-model="form.age"
            />
            <span class="error-feedback" v-if="v$.form.age.$error">{{
              v$.form.age.$errors[0].$message
            }}</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الوظيفه</label>
          <multiselect
            v-model="jobType"
            :options="postionOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            select-label=""
            :hide-selected="true"
            :clear-on-select="true"
            @update:modelValue="updatePostion"
          ></multiselect>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useEmployeesAddStore } from "@/stores/employees/EmployeesAddStore";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { mapState } from "pinia";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useEmployeesAddStore(),
      v$: useVuelidate(),
      form: {
        identity_type: "",
        marital_status: "",
        identity_number: "",
        date_of_birth: "",
        age: "",
        gender: "",
        name: "",
        phone: "",
        email: "",
        password: "",
        image: null,
        imageSrc: "",
        is_employed: 0,
        certificate_images: [],
        certificateImagesSrc: [],
        job_type_id: null,
      },
      jobType: null,
      postionOptions: [],
    };
  },

  validations() {
    return {
      form: {
        name: { required },
        phone: { required },
        email: { required, email },
        password: { required },
        age: { required },
      },
    };
  },
  computed: {
    ...mapState(useEmployeesAddStore, {
      postions: (state) => state.postion,
    }),
  },
  methods: {
    updatePostion() {
      console.log(this.jobType.id)
      this.form.job_type_id = this.jobType.id
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    triggerCertificatesInput() {
      this.$refs.certificatesInput.click();
    },

    handleCertificatesChange(event) {
      const files = Array.from(event.target.files);
      this.form.certificate_images.push(...files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.certificateImagesSrc.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    removeCertificate(index) {
      this.form.certificate_images.splice(index, 1);
      this.form.certificateImagesSrc.splice(index, 1);
    },

    async submitForm() {
      // console.dir(this.form + "form");
      this.v$.$validate();
      if (this.v$.$error) return; // تأكد من وجود أخطاء قبل المتابعة

      try {
        const employeesStore = useEmployeesAddStore();
        if (!employeesStore) {
          throw new Error("Failed to load employees store");
        }

        await employeesStore.addEmployee(this.form);
        this.$router.push("/employees");
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
    async fetchPostion() {
      await this.store.fetchPositions();

      this.postionOptions = this.store.postion;
    },
  },
  mounted() {
    this.fetchPostion();
  },
};
</script>

