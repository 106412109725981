<template>
  <div class="Groups-add">
    <header-pages title="اضافة مجموعة" :showButton="false" link="/groups" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم المجموعة</label>
          <div class="input">
            <input
              type="text"
              placeholder="أدخل أسم المجموعة"
              v-model="group.title"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">تاريخ البدايه </label>
            <input v-model="group.start_date" type="date" placeholder="من  " />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">تاريخ النهايه </label>
            <input v-model="group.end_date" type="date" placeholder="الي  " />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">اختر المدرس</label>
          <multiselect
            v-model="group.Teatcher_value"
            :options="TeatcherOptions"
            track-by="id"
            label="title"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">أختر الكورس</label>
          <multiselect
            v-model="group.course_value"
            :options="courseOptions"
            track-by="id"
            label="title"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="check-div">
            <input type="checkbox" v-model="group.allCourses" />
            <label for="name">محتوي الكورس كامل</label>
          </div>
          <multiselect
            v-if="!group.allCourses"
            v-model="group.stage_value"
            :options="stageOptions"
            placeholder="أختار محتوي الكورس"
            track-by="id"
            label="title"
            :disabled="allCourses"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="check-div">
            <input
              type="checkbox"
              v-model="group.allDisabilities"
              @change="toggleDisabilitySelect"
            />
            <label for="name">جميع الاعاقات</label>
          </div>
          <multiselect
            v-if="!group.allDisabilities"
            v-model="group.Disipilty_value"
            :options="DisiplityOptions"
            placeholder="أختر الاعاقات"
            track-by="id"
            label="title"
            :disabled="allDisabilities"
          ></multiselect>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="week" v-for="(day, index) in days" :key="index">
              <div class="chek">
                <div class="input">
                  <!-- Bind the checkbox to the selected state of each day -->
                  <input type="checkbox" v-model="day.selected" />
                </div>
                <!-- Display the day name dynamically -->
                <label :for="day.title">{{ day.title }}</label>
              </div>

              <!-- Only show the time inputs if the day is selected -->
              <div class="times" v-if="day.selected">
                <div class="input">
                  <label for="time_start">من</label>
                  <!-- Bind each day's time_start value -->
                  <input type="time" v-model="day.time_start" />
                </div>
                <div class="input">
                  <label for="time_end">إلى</label>
                  <!-- Bind each day's time_end value -->
                  <input type="time" v-model="day.time_end" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useGroupsAddStore } from "@/stores/Groups/GroupsAddStore";

export default {
  name: "GroupsAdd",
  data() {
    return {
      groupAddStore: useGroupsAddStore(),
      group: {
        title: "",
        allDisabilities: "",
        start_date: "",
        end_date: "",
        allCourses: false,
        days: [],
        selectedDay: null,

        Teatcher_value: "",
        Disipilty_value: "",
        stage_value: "",
        course_value: "",
      },
      courseOptions: [
        { id: 1, title: "Course 1" },
        { id: 2, title: "Course 2" },
        { id: 3, title: "Course 3" },
      ],
      stageOptions: [
        { id: 1, title: "Stage 1" },
        { id: 2, title: "Stage 2" },
        { id: 3, title: "Stage 3" },
      ],
      DisiplityOptions: [
        { id: 1, title: "Disipility 1" },
        { id: 2, title: "Disipility 2" },
        { id: 3, title: "Disipility 3" },
      ],
      TeatcherOptions: [
        { id: 1, title: "Teatcher 1" },
        { id: 2, title: "Teatcher 2" },
        { id: 3, title: "Teatcher 3" },
      ],
    };
  },
  components: {
    HeaderPages,
    Multiselect,
  },
  async mounted() {
    await this.groupAddStore.fetchCourses();
    await this.groupAddStore.fetchTeachers();
    await this.groupAddStore.fetchCourseContent();
    await this.groupAddStore.fetchDisabilities();
    await this.groupAddStore.fetchDays();

    this.courseOptions = this.groupAddStore.courses;
    this.TeatcherOptions = this.groupAddStore.teachers;
    this.stageOptions = this.groupAddStore.courseContents;
    this.DisiplityOptions = this.groupAddStore.disabilities;
    this.days = this.groupAddStore.days;
  },
  methods: {
    submitForm() {
      // Construct the days array in the required format
      const selectedDays = this.days
        .filter((day) => day.selected)
        .map((day) => ({
          day_id: day.day_id,
          start_time: day.time_start,
          end_time: day.time_end,
        }));

      // Assign the selected days in the format: days[0][start_time], days[0][end_time], days[0][day_id]
      this.group.days = selectedDays;

      // Handling allCourses and allDisabilities as boolean-to-integer
      this.group.allCourses = this.group.allCourses ? 1 : 0;
      this.group.allDisabilities = this.group.allDisabilities ? 1 : 0;

      // Ensure multiselect values are set to their corresponding ids
      this.group.stage_value = this.group.stage_value.id || null;
      this.group.course_value = this.group.course_value?.id || null;
      this.group.Disipilty_value = this.group.Disipilty_value?.id || null;
      this.group.Teatcher_value = this.group.Teatcher_value?.id || null;

      // Submit the group data
      this.groupAddStore.addGroups(this.group);
    },
  },
};
</script>
