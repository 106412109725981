<template>
  <div class="edit-Complaints">
    <HeaderPages title=" إضافة هشتاج جديد " :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="avatar-uploader">
        <label for="avatar"> اضافة صورة </label>
        <input
          type="file"
          id="avatar"
          @change="handleFileChange"
          accept="image/*"
          ref="fileInput"
          style="display: none"
        />
        <div v-if="!form.image" class="upload-icon" @click="triggerFileInput">
          <i class="fa fa-camera"></i>
          <span>اختيار صورة</span>
        </div>
        <div v-if="imagePreview" class="avatar-preview">
          <img :src="imagePreview" alt="Avatar Preview" />
          <i class="fa fa-times delete-icon" @click="removeImage"></i>
        </div>
        <!--            <span class="error-feedback" v-if="v$.form.imageSrc.$error"-->
        <!--              >{{ v$.form.imageSrc.$errors[0].$message }}-->
        <!--            </span>-->
      </div>

      <div class="row">
        <div class="col-lg-12 col-md-7 col-12">
          <label for="name"> اسم الهشتاج </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="  اكتب اسم الهشتاج  "
              v-model="form.title"
            />
          </div>
        </div>

        <div class="col-lg-12 col-md-7 col-12">
          <label for="name"> الوصف </label>
          <textarea
            id="w3review"
            name="w3review"
            v-model="form.description"
          ></textarea>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapActions } from "pinia";
import { useBlogHashtagAddStore } from "@/stores/BlogHashtag/BlogHashtagAddStore";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        title: "",
        description: "",
        image: null,
      },
      imagePreview: null,
    };
  },
  validations() {
    return {
      form: {
        title: { required },
        description: { required },
        image: { required },
      },
    };
  },
  methods: {
    ...mapActions(useBlogHashtagAddStore, ["addBlogHashtag"]),
    async submitForm() {
      await this.v$.$validate();
      if (this.v$.$error) return;
      await this.addBlogHashtag(this.form);
    },
    handleFileChange(event) {
      this.form.image = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imagePreview = e.target.result;
      };
      reader.readAsDataURL(this.form.image);
    },
    removeImage() {
      this.image = null;
      this.form.image = "";
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  height: 100px;
  border: 1px solid var(--main);
  border-radius: 10px;
}
</style>
