<template>
  <div class="add-Department">
    <HeaderPages title="إضافة قسم جديد" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-12">
          <label for="name"> اسم القسم </label>
          <div class="input">
            <input
              type="text"
              v-model="form.title"
              id="name"
              placeholder="اكتب اسم القسم  "
            />
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapActions } from "pinia";
import { useBlogAddStore } from "@/stores/BlogCategory/BlogCategoryAddStore";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        title: "",
      },
    };
  },
  validations() {
    return {
      form: {
        title: { required },
      },
    };
  },
  methods: {
    ...mapActions(useBlogAddStore, ["addBlogCategory"]),
    async submitForm() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      await this.addBlogCategory(this.form);
    },
  },
};
</script>
