<template>
  <div class="employees-edit">
    <header-pages title="تعديل مجموعة" :showButton="false" link="/groups" />
    <form action="" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-12 col-md-6 col-12">
          <label for="name">أسم المجموعة</label>
          <div class="input">
            <textarea
              type="text"
              id="name"
              placeholder="أدخل أسم المجموعة"
              width="100%"
            ></textarea>
            <!-- <span class="error-feedback" v-if="v$.form.name.$error">{{
              v$.form.name.$errors[0].$message
            }}</span> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> الكورسات </label>
          <multiselect
            v-model="Groups_value"
            :options="GroupsOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            @update:modelValue="updateCurricula"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses"> المدرسين </label>
          <multiselect
            v-model="Teatcher_value"
            :options="TeatcherOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            @update:modelValue="updateTeatcher"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">من </label>
            <input type="date" placeholder="من  " />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">الي </label>
            <input type="date" placeholder="الي  " />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div style="display: flex">
            <input
              type="checkbox"
              v-model="allDisabilities"
              @change="toggleDisabilitySelect"
            />
            <label for="name">جميع الاعاقات</label>
          </div>
          <multiselect
            v-model="Disipilty_value"
            :options="DisiplityOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            :disabled="allDisabilities"
            @update:modelValue="updateDisiplity"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div style="display: flex">
            <input type="checkbox" v-model="allCourses" />
            <label for="name">محتوي الكورس كامل </label>
          </div>
          <multiselect
            v-model="Courses_value"
            :options="CoursesOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            :disabled="allCourses"
            @update:modelValue="updateCourses"
          ></multiselect>
        </div>
        <div
          v-for="(day, index) in days"
          :key="index"
          class="col-lg-12 col-md-12 col-12"
          id="all"
        >
          <input type="radio" :value="index + 1" v-model="selectedDay" />
          <label>{{ day.label }}</label>
          <div class="dayes">
            <label>من</label>
            <input
              id="start"
              type="date"
              :disabled="selectedDay !== index + 1"
            />
            <label>الي</label>
            <input id="end" type="date" :disabled="selectedDay !== index + 1" />
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
export default {
  name: "GroupsAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      selectedDay: null,
      days: [
        { label: "الاحــــد" },
        { label: "الاثنيـن" },
        { label: "الثلاثاء" },
        { label: "الاربعاء" },
        { label: "الخميس" },
      ],

      allDisabilities: false,
      allCourses: false,
      CoursesOptions: [
        {
          id: 1,
          title: "Course 1",
        },
        {
          id: 2,
          title: "Course 2",
        },
        {
          id: 3,
          title: "Course 3",
        },
      ],
      Courses_value: [],
      Disipilty_value: [],
      DisiplityOptions: [
        {
          id: 1,
          title: "Disipilty 1",
        },
        {
          id: 2,
          title: "Disipilty 2",
        },
        {
          id: 3,
          title: "Disipilty 3",
        },
      ],
      TeatcherOptions: [
        {
          id: 1,
          title: "Teacher 1",
        },
        {
          id: 2,
          title: "Teacher 2",
        },
        {
          id: 3,
          title: "Teacher 3",
        },
      ],
      Teatcher_value: [],
      GroupsOptions: [
        {
          id: 1,
          title: "Group 1",
        },
        {
          id: 2,
          title: "Group 2",
        },
        {
          id: 3,
          title: "Group 3",
        },
      ],
      Groups_value: [],
    };
  },
  methods: {
    isSelected(day) {
      return (
        this.selectedDay !== null && this.selectedDay === this.days.indexOf(day)
      );
    },
  },
};
</script>
<style scoped>
#all {
  display: flex;
  margin-top: 15px;
}
.dayes {
  margin-right: 34px;
}
textarea {
  width: 100%;
  height: 70px;
  border: 1px solid #06797e;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: none;
  color: #a6a6a6;
}
#start {
  width: 280px;
  height: 40px;
  margin-right: 15px;
  border: 1px solid #06797e;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}
#end {
  width: 280px;
  height: 40px;
  margin-right: 15px;
  border: 1px solid #06797e;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}
label {
  margin-top: 8px;
}
</style>
