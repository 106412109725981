<template>
  <div class="add-Complaints">
    <HeaderPages title="إضافة مسابقه جديدة" :showButton="false" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar"> اضافة صورة للسليدر</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.image"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="image" class="avatar-preview">
              <img :src="image" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <!--            <span class="error-feedback" v-if="v$.form.imageSrc.$error"-->
            <!--              >{{ v$.form.imageSrc.$errors[0].$message }}-->
            <!--            </span>-->
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
          <label for="name"> عنوان</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="form.name"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.name.$error">
            {{ v$.form.name.$errors[0].$message }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> من </label>
          <div class="input">
            <input
              type="date"
              placeholder="ادخل تاريخ الميلاد"
              v-model="form.start_date"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.start_date.$error">
            {{ v$.form.start_date.$errors[0].$message }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> الي</label>
          <div class="input">
            <input
              type="date"
              placeholder="ادخل تاريخ الميلاد"
              v-model="form.end_date"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.end_date.$error">
            {{ v$.form.end_date.$errors[0].$message }}
          </span>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
          <label for="name"> الوصف</label>
          <br />
          <textarea
            id="w3review"
            name="w3review"
            v-model="form.description"
          ></textarea>
          <span class="error-feedback" v-if="v$.form.description.$error">
            {{ v$.form.description.$errors[0].$message }}
          </span>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapActions } from "pinia";
import { useCompetitionAddStore } from "@/stores/competition/CompetitionAddStore";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      image: "",
      form: {
        imageSrc: "",
        end_date: "",
        start_date: "",
        image: null,
        name: "",
        description: "",
      },
      CurriculaOptions: [],
    };
  },

  validations() {
    return {
      form: {
        end_date: { required },
        start_date: { required },
        name: { required },
        description: { required },
      },
    };
  },

  methods: {
    ...mapActions(useCompetitionAddStore, ["addCompetition"]),
    // add img 1
    handleFileChange(event) {
      this.form.image = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(this.form.image);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage() {
      this.image = null;
      this.form.image = "";
    },
    async submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      await this.addCompetition(this.form);
    },
    // add img 1
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
textarea {
  width: 100%;
  height: 100px;
  border: 1px solid var(--main);
  border-radius: 10px;
}
</style>
