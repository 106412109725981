<template>
  <div class="Complaints">
    <header-pages
      title="المسابقات"
      button="+ اضافة مسابقة جديدة   "
      link="/add-competion"
    ></header-pages>
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteCompetitions"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { useCompetitionStore } from "@/stores/competition/CompetitionStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
export default {
  name: "GroupsIndex",
  components: {
    PaginationPage,
    HeaderPages,
    TablesPageVue,
  },
  data() {
    return {
      tableHeaders: [
        "رقم المسابقة",
        "	اسم المسابقة",
        "تاريخ البداية",
        "تاريخ الانتهاء",
      ],
      editLink: "/edit-competion",
      viewLink: "/view-group",
    };
  },
  computed: {
    ...mapState(useCompetitionStore, {
      competitions: (state) => state.competitions,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      return this.competitions.map((exa) => [
        exa.id,
        exa.name,
        exa.start_date,
        exa.end_date,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const curriculaStore = useCompetitionStore();
      curriculaStore.fetchCompetitions(page);
    },
    async handleDeleteCompetitions(id) {
      const examsStore = useCompetitionStore();
      await examsStore.deleteCompetitions(id);
    },
  },
  created() {
    const curriculaStore = useCompetitionStore();
    curriculaStore.fetchCompetitions();
  },
};
</script>
