import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useUpdateBlogCategoryStore = defineStore("blogAdd", {
  state: () => ({
    blogCategory: {},
  }),
  actions: {
    async getUpdateBlogCategory(id) {
      const response = await axios
        .post(`fetch_blog_category_details`, { id: id })
        .then((response) => {
          this.blogCategory = response.data.data;
        });
    },

    async UpdateBlogCategory(data) {
      this.loading = true;
      try {
        const response = await axios
          .post(`edit_blog_category`, data)
          .then((response) => {
            this.blog = response.data.data;
            this.loading = false;
            Swal.fire({
              icon: "success",
              title: "Blog Category updated successfully",
              showConfirmButton: false,
              timer: 1500,
            });
            router.push("/index-department");
          });
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
  },
});
