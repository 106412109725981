import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useEmployeesAddStore = defineStore("EmployeesAdd", {
  state: () => ({
    employees: [],
    postion: [],
    job_type_id: "",
  }),
  actions: {
    async fetchPositions() {
      try {
        const response = await axios.get("organization_fetch_job_types");
        if (response.data.status === true) {
          this.postion = response.data.data;
          this.job_type_id = this.postion.map((pos) => pos.id);
          console.log(this.postion, "sssss");
          console.log(this.job_type_id, "sssss");
        } else {
          console.log("Error fetching disabilities");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async addEmployee(employeeData) {
      try {
        const formData = new FormData();

        // Append each property to FormData
        Object.keys(employeeData).forEach((key) => {
          if (Array.isArray(employeeData[key])) {
            employeeData[key].forEach((item) => {
              formData.append(`${key}[]`, item); // Use key[] for array values
            });
          } else {
            formData.append(key, employeeData[key]);
          }
        });

        // Log FormData entries for debugging
        for (const [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`, "FormData");
        }

        const response = await axios.post("add_employee", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log("API Response:", response.data);

        if (response.data.status) {
          Swal.fire(
            "Success",
            "Employee has been saved successfully.",
            "success"
          );
          this.employees.push(response.data.data); // Assuming you want to keep track of added employees
        } else {
          Swal.fire(
            "Error",
            "There was a problem saving the employee: " + response.data.message,
            "error"
          );
        }
      } catch (error) {
        console.error("Error saving employee:", error);
        Swal.fire("Error", "There was a problem saving the employee.", "error");
      }
    },
  },
});
