import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useGroupsStore = defineStore("groups", {
  state: () => ({
    groups: [],
  }),
  actions: {
    async fetchGroups(page = 1) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      try {
        const response = await axios.post(`fetch_groups?page=${page}`);
        const paginationStore = usePaginationStore();
        const { current_page, from, last_page, per_page, to, total } =
          response.data.data.meta;

        if (response.data.status) {
          this.Groups = response.data.data.data;
          // console.log(this.Groups, "Groups List");

          paginationStore.setPage(current_page);
          paginationStore.setfrom(from);
          paginationStore.setlastpage(last_page);
          paginationStore.setperpage(per_page);
          paginationStore.setto(to);
          paginationStore.settotal(total);
        }

        this.ismaster = this.Groups.map((emp) => emp.is_master);
        // console.log(this.ismaster, "is master");
      } catch (error) {
        // console.error("Error fetching Groups:", error);
      } finally {
        loadingStore.stopLoading();
      }
    },

    // async deleteEmployee(id) {
    //   try {
    //     const result = await Swal.fire({
    //       title: "هل انتا متاكد من عملية المسح?",
    //       text: "لن تتمكن من التراجع عن هذا!",
    //       icon: "warning",
    //       showCancelButton: true,
    //       confirmButtonColor: "#3085d6",
    //       cancelButtonColor: "#d33",
    //       confirmButtonText: "نعم، احذفه!",
    //     });

    //     if (result.isConfirmed) {
    //       await axios.post("delete_employee", { id });
    //       this.Groups = this.Groups.filter((emp) => emp.id !== id);
    //       Swal.fire("تم الحذف!", "تم حذف الموظف", "success");
    //     }
    //   } catch (error) {
    //     console.error("Error deleting employee:", error);
    //     Swal.fire(
    //       "Error!",
    //       "There was an error deleting the employee.",
    //       "error"
    //     );
    //   }
    // },
  },
});
