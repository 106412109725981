<template>
  <div class="employees">
    <HeadersPages
      title="الأمتحانات"
      button="+ اضافة أمتحان"
      link="/exams-add"
    />
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteExams"
      :ismaster="ismaster"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useExamsStore } from "@/stores/exams/ExamsStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      tableHeaders: ["ID", "اسم الأمتحان", "حالة الامتحان", "المجموعة"],
      editLink: "/exams-edit",
      viewLink: "/view-exam",
    };
  },
  computed: {
    ...mapState(useExamsStore, {
      exams: (state) => state.exams,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      return this.exams.map((exa) => [
        exa.id,
        exa.name,
        exa.status,
        exa.groups.map((item)=> item.title),
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const curriculaStore = useExamsStore();
      curriculaStore.fetchEmployees(page);
    },
    async handleDeleteExams(id) {
      const examsStore = useExamsStore();
      await examsStore.deleteExams(id);
    },
  },

  async mounted() {
    const examsStore = useExamsStore();
    await examsStore.fetchExams();
  },
};
</script>
