<template>
  <div class="Groups-add">
    <header-pages title="اضافة دورة" :showButton="false" link="/add-course" />
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الدورة</label>
          <div class="input">
            <input
              v-model="form.name"
              type="text"
              id="name"
              placeholder="أدخل أسم الدورة"
            />
          </div>
          <span class="error-feedback" v-if="v$.form.name.$error">{{
            getErrorMessage(v$.form.name)
          }}</span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Classes">الفصول الدراسيه</label>
          <multiselect
            v-model="Classes_value"
            :options="ClassesOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            @update:modelValue="updateClasses"
          />
          <span class="error-feedback" v-if="v$.form.Classes_value.$error">{{
            getErrorMessage(v$.form.Classes_value)
          }}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">السنوات الدراسيه</label>
          <multiselect
            v-model="Courses_value"
            :options="CoursesOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="false"
            @update:modelValue="updateCourses"
          />
          <span class="error-feedback" v-if="v$.form.Courses_value.$error">{{
            getErrorMessage(v$.form.Courses_value)
          }}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Disapilty">الاعاقات</label>
          <multiselect
            v-model="Disapility_value"
            :options="DisapilityOptions"
            :close-on-select="true"
            track-by="id"
            label="title"
            :multiple="true"
            @change="updateDisapility"
          />
          <span class="error-feedback" v-if="v$.form.Disapility_value.$error">{{
            getErrorMessage(v$.form.Disapility_value)
          }}</span>
        </div>
        <!-- <div class="col-lg-6 col-md-6 col-12">
          <label for="Curricla">المنهج</label>
          <multiselect
            v-model="curriculum_valus"
            :options="CurriculaOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="true"
            @update:modelValue="updateCurricula"
          />
        </div> -->

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Stages">المراحل</label>
          <multiselect
            v-model="Stages_value"
            :options="StagesOptions"
            :close-on-select="true"
            track-by="id"
            label="title"
            :disabled="allcurricula"
            :multiple="true"
            @change="updateStages"
          />
          <span class="error-feedback" v-if="v$.form.Stages_value.$error">{{
            getErrorMessage(v$.form.Stages_value)
          }}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div style="display: flex; align-items: center">
            <input
              type="checkbox"
              v-model="allcurricula"
              @change="togglecurriculaSelect"
            />
            <label>المنهج كامل</label>
          </div>
          <multiselect
            v-model="curriculum_valus"
            :options="CurriculaOptions"
            :close-on-select="false"
            track-by="id"
            label="title"
            :multiple="true"
            @update:modelValue="updateCurricula"
          />
          <span class="error-feedback" v-if="v$.form.curriculum_valus.$error">{{
            getErrorMessage(v$.form.curriculum_valus)
          }}</span>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save" @click="save()">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import { useCoursesAddStore } from "@/stores/courses/CoursesAddStore";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapState } from "pinia";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "CoursesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        imageSrc: "",
        image: null,
        name: "",

        curricula: [],
        curriculum_ids: [],
      },
      curriculum_valus: [],
      CurriculaOptions: [],
      allcurricula: false,
      ClassesOptions: [],
      CoursesOptions: [],
      DisapilityOptions: [],
      CurriclaOptions: [],
      StagesOptions: [],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        curriculum_valus: { required },
        imageSrc: { required },
        Stages_value: { required },
        curriculum_ids: { required },
        Courses_value: { required },
        Classes_value: { required },
        Disapility_value: { required },
      },
    };
  },
  computed: {
    ...mapState(useCoursesAddStore, {
      courses: (state) => state.courses,
    }),
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    updateCurricula() {
      this.form.curriculum_ids = this.curriculum_valus.map((dis) => dis.id);
    },
    async submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;

      try {
        const CoursesStore = useCoursesAddStore();
        if (!CoursesStore) {
          throw new Error("Failed to load Courses store");
        }

        this.form.curriculum_ids = this.curriculum_valus
          .map((dis) => dis.id)
          .filter((id) => id !== undefined && id !== null);

        await CoursesStore.addCourse(this.form);
        this.$router.push("/courses");
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
    async getCurricula() {
      const curriculumStore = useCoursesAddStore();
      await curriculumStore.fetchCurricula();
      this.CurriculaOptions = curriculumStore.Curricula;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    save() {
      console.log("errorrrrrr");
      this.v$.$validate();
      if (!this.v$.$error) {
        // this.submitForm();
      }
    },
  },
  mounted() {
    this.getCurricula();
  },
};
</script>

<style scoped>
.error-feedback {
  color: red;
  font-size: 0.85rem;
}
</style>
